import React, { useEffect } from 'react';
import { FaArrowUp } from "react-icons/fa";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

export default function Footer() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { targetId } = state || {};
  useEffect(() => {
    if (targetId) {
        const el = document.getElementById(targetId);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}, [targetId]);

  return (
    <footer className="bg-[#0c0c0c] text-white py-10 mt-12 relative overflow-hidden playfair-display ">
      {/* Paski tła */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-[#e02525] to-[#b12222] opacity-50"></div>

      <nav className="container mx-auto flex flex-col lg:flex-row justify-center items-center relative z-10">
            <ul className="flex flex-col lg:flex-row gap-6 mb-16">
                <li onClick={() => navigate("/o-nas", { state: { targetId: "onas" } })} className="cursor-pointer transition-transform transform hover:scale-105">
                    O nas
                </li>
                <li onClick={() => navigate("/", { state: { targetId: "oferta" } })} className="cursor-pointer transition-transform transform hover:scale-105">
                    Oferta
                </li>
                <li onClick={() => navigate("/", { state: { targetId: "kontakt" } })} className="cursor-pointer transition-transform transform hover:scale-105">
                    Kontakt
                </li>
            </ul>
        </nav>

      <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center relative z-10">
        <Link to="/">
          <img src="/logo.png" alt="ltl-express logo" className="w-48 mb-4 lg:mb-0" />
        </Link>
        <div className="flex flex-col items-start mx-5">
          <p className="flex items-center gap-2">
            <FaHome size={24} /> <span className="font-bold">Dane firmy:</span>
          </p>
          <p className="flex flex-col">
            LTL-EXPRESS Maciej Prochowski<br />
            ul. Przemysłowa 20c<br />
            44-290 Jejkowice
          </p>
        </div>

        <div className="h-16 border-l border-gray-300 mx-4 hidden lg:block"></div>

        <div className="flex flex-col items-start">
          <a href="tel:668191265" style={{ textDecoration: 'none', color: 'white' }} className="flex items-center gap-2 mb-2">
            <IoIosPhonePortrait size={24} /> <span className="font-bold">Telefon:</span> 665 566 557
          </a>
          <a href="mailto:maciej@ltl-express.pl" style={{ textDecoration: 'none', color: 'white' }} className="flex items-center gap-2">
            <MdEmail size={24} /> <span className="font-bold">E-mail:</span> maciej@ltl-express.pl
          </a>
        </div>
      </div>

      <ScrollLink
        activeClass="active"
        to="up"
        spy={true}
        smooth={true}
        offset={50}
        duration={500}
        className="flex justify-center my-10"
      >
        <button type="button" className="bg-[#e02525] p-3 rounded-full shadow-lg transition-transform transform hover:scale-110">
          <FaArrowUp className="text-white" />
        </button>
      </ScrollLink>

      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center text-center lg:text-left px-4 relative z-10">
        <p className="flex justify-center items-center flex-wrap">
          <span className="font-bold">Copyright © 2024 LTL-Express</span> | Wszelkie prawa zastrzeżone.
        </p>
        <p className="mt-2 lg:mt-0">Realizacja: MTM Group</p>
      </div>

      {/* Falista krawędź na dole */}
      <svg
        className="absolute bottom-0 left-0 w-full"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path
          fill="#e02525" // Czerwony kolor
          d="M0,64L30,96C60,128,120,192,180,218.7C240,245,300,235,360,229.3C420,224,480,224,540,213.3C600,203,660,181,720,186.7C780,192,840,224,900,218.7C960,213,1020,171,1080,149.3C1140,128,1200,128,1260,144C1320,160,1380,192,1410,208L1440,224L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320H0Z"
        ></path>
      </svg>
    </footer>
  );
}
