import React from 'react'
import Home from './pages/Home'
import Onas from './pages/Onas'

import {Routes, Route} from 'react-router-dom' 

export default function App() {

  return (
   <>
<Routes>
  <Route path ="/" element ={<Home />}></Route>
  <Route path ="/o-nas" element ={<Onas/>}></Route>
</Routes>

</>
    
  )
}
