import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImTruck } from "react-icons/im";
import { FaArrowRight } from 'react-icons/fa';
import FlyingLasers from './FlyingLasers';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function Contact() {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [msg, setMsg] = useState('');
  const [err, setErr] = useState('');
  const [mess, setMess] = useState('');
  const [loading, setLoading] = useState(false);
  const [iconVisible, setIconVisible] = useState(false);
  const contactRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (contactRef.current) {
        const { top } = contactRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top < windowHeight && top > 0) {
          setIconVisible(true);
        } else {
          setIconVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sendMail = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('msg', msg);
    const url = 'api/send.php';
    const res = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (res.ok) {
      setMsg('');
      setPhone('');
      setEmail('');
      setMess('Dziękuję za kontakt! Postaram się odpowiedzieć jak najszybciej!');
      setTimeout(() => setErr(''), 5000);
    } else {
      setErr('Niestety, Twój email do nas nie dotarł. Spróbuj jeszcze raz.');
      setTimeout(() => setErr(''), 5000);
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  return (
    <div id="kontakt" ref={contactRef} className="relative playfair-display">
      <FlyingLasers />
      <div className="flex flex-col items-center justify-center min-h-screen relative z-10">
        <div className="flex flex-row gap-2 justify-center items-center text-white pt-20 text-[36px] md:text-[48px]">
          <span className={`icon ${iconVisible ? 'animate-icon' : ''}`}>
            <ImTruck className="text-red-600" size={56} />
          </span>
          <h2 className="font-bold text-3xl md:text-4xl ml-2">KONTAKT</h2>
        </div>
        <p className="text-white mb-20 mt-3 text-[14px] lg:text-[18px] flex justify-center items-center mx-5">
          Wyślij zapytanie przez formularz lub skontakuj się telefonicznie
        </p>
        <Form onSubmit={sendMail} className="w-[90%] md:w-3/4 lg:w-2/3 xl:w-1/3 mb-3 p-10 shadow-2xl bg-gradient-to-b from-black to-red-800 rounded-3xl relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-red-600 to-red-800 opacity-20 rounded-3xl"></div>

          <Form.Group className="mb-4 relative z-10" controlId="formBasicEmail">
            <Form.Label className="text-white font-semibold">Adres e-mail</Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder="Wpisz email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="rounded-lg border-2 border-transparent focus:border-white transition duration-300 ease-in-out bg-red-300"
            />
          </Form.Group>
          
          <Form.Group className="mb-4 relative z-10" controlId="formBasicPhone">
            <Form.Label className="text-white font-semibold">Telefon</Form.Label>
            <Form.Control
              name="phone"
              type="tel"
              placeholder="Wpisz numer telefonu"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="rounded-lg border-2 border-transparent focus:border-white transition duration-300 ease-in-out bg-red-300"
            />
          </Form.Group>

          <Form.Group className="mb-4 relative z-10" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="text-white font-semibold">Twoja wiadomość</Form.Label>
            <Form.Control
              as="textarea"
              autoCorrect
              rows={5}
              style={{ resize: "none" }}
              name="msg"
              required
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              className="rounded-lg border-2 border-transparent focus:border-white transition duration-300 ease-in-out bg-red-300"
            />
          </Form.Group>

          <Button
            style={{ backgroundColor: '#b12222', color: 'white', borderColor: '#9f1c1c' }}
            type="submit"
            disabled={loading}
            className="w-full bg-[#e02525] text-white font-semibold py-2 rounded-md shadow-lg transition-transform duration-300 ease-in-out hover:bg-[#9f1c1c] transform hover:scale-105"
          >
            {loading ? 'Wysyłanie...' : 'Wyślij'}
          </Button>

          {err && <p className="text-white bg-red-500 text-xl mt-5 p-3 h-auto w-full">{err}</p>}
          {mess && <p className="text-white text-xl mt-5 p-3 h-auto w-full">{mess}</p>}
        </Form>

        <div className="flex justify-center items-center pt-20">
          <div className="cursor-pointer w-content bg-[#b12222] my-1 md:my-2 lg:my-5 text-white text-[14px] lg:text-[24px] py-1 px-3 md:px-5 hover:rounded-3xl hover:-translate-y-1 transition-all flex-row gap-2 lg:gap-5 flex justify-center items-center no-underline"
            onClick={() => { navigate("/o-nas", { state: { targetId: "onas" } }); }}>
            Jak dojechać <FaArrowRight size={24} />
          </div>
        </div>
      </div>

    </div>
  );
}
