import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';
import { ImTruck } from "react-icons/im";
import { FaTools, FaUserTie, FaGlobe } from 'react-icons/fa';

export default function Why() {
  const [iconVisible, setIconVisible] = useState(false);
  const whyRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (whyRef.current) {
        const { top } = whyRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (top < windowHeight && top > 0) {
          setIconVisible(true);
        } else {
          setIconVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={whyRef} className="w-full h-auto flex flex-col justify-center items-center pb-20 playfair-display ">
      <div className="flex-row gap-2 text-white my-20 text-3xl md:text-4xl font-bold flex justify-center items-center pb-10">
        <span className={`icon ${iconVisible ? 'animate-icon' : ''}`}>
          <ImTruck className ="text-red-600"  size={56} />
        </span>
        DLACZEGO MY?
      </div>
      <div className="w-screen flex flex-col lg:flex-row flex-wrap gap-5 justify-center items-center">
        <Card 
          title="Kompleksowość obsługi" 
          icon={<FaTools size={45} />} 
          desc="Nasze usługi są w pełni kompleksowe. Nasza firma ma wieloletnie doświadczenie w zakresie pełnionych usług. Jesteśmy zarówno elastyczni, jak i skuteczni w działaniach."
          bool="hidden"
        />
        <Card 
          title="Globalny zakres usług" 
          icon={<FaGlobe size={45} />} 
          desc="Świadczymy nasze usługi na najwyższym poziomie. Zapewniamy nie tylko wysoką jakość surowców, ale także usług. Dbamy o stały kontakt z klientem na każdym etapie współpracy."
          bool="hidden"
        />
        <Card 
          title="Profesjonalne wsparcie" 
          icon={<FaUserTie size={45} />} 
          desc="Zapewniamy profesjonalne wsparcie naszych wykwalifikowanych spedytorów. Całodobowy kontakt koordynacyjny oraz indywidualne podejście do każdego klienta."
          link="/o-nas"
          target="pomiar"
          bool="flex"
        />
      </div>
      
 
    </div>
  );
}
