import React from 'react'
import {useNavigate} from 'react-router-dom'
import { FaArrowRight} from "react-icons/fa";

export default function Card(props) {
  const navigate =useNavigate()
  return (
    <div className ="mb-10 basis-1/4  w-content w-[90%] md:w-3/4 lg:w-1/5  h-full min-h-[420px] flex flex-col  rounded-xl relative  shadow-2xl shadow-black hover:scale-105 transition-all"> 
          <div className="absolute top-0 left-1/2  bg-red-600 -translate-x-1/2 -translate-y-1/2 p-3 rounded-xl ">{props.icon}</div>
          <div className="flex flex-col mt-20 justify-center items-center">
        <h3 className=" text-white  w-auto text-center text-[20px] lg:text-[25px] pb-10">{props.title}
        <hr />
        </h3>
        <div className="w-4/5 text-white text-[16px] lg:text-[20px]">{props.desc}</div>
        <div className={`${props.bool}  py-10` } >
        <div className="cursor-pointer w-content bg-red-600 my-1 md:my-2 lg:my-8 text-white text-[18px] lg:text-[24px] py-1 px-3 md:px-5 hover:rounded-3xl hover:-translate-y-1 transition-all  flex-row gap-2 lg:gap-5 flex justify-center items-center no-underline"
               onClick={()=> {navigate(`${props.link}`, {state: {targetId:`${props.target}`}});}}>Zobacz więcej <FaArrowRight size={24}/></div>
    </div>
    </div>
    </div>
  )
}
