import React from 'react';

const FlyingLasers = () => {
  const gradients = Array.from({ length: 10 }).map((_, index) => ({
    top: `${Math.random() * 100}%`,
    left: `${Math.random() * 100}%`,
    size: Math.random() * 200 + 100, // rozmiar od 100 do 300px
  }));

  return (
    <div className="absolute inset-0 overflow-hidden">
      {gradients.map((gradient, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            width: `${gradient.size}px`,
            height: `${gradient.size}px`,
            background: `radial-gradient(circle, rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0) 70%)`,
            opacity: 0.5,
            top: gradient.top,
            left: gradient.left,
            animation: `move ${Math.random() * 10 + 20}s linear infinite`,
          }}
        />
      ))}

      <style jsx>{`
        @keyframes move {
          0% {
            transform: translate(-50%, -50%);
          }
          100% {
            transform: translate(calc(100vw + 50px), calc(100vh + 50px));
          }
        }
      `}</style>
    </div>
  );
};

export default FlyingLasers;
