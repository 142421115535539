import React, { useRef } from 'react';
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame
} from "framer-motion";

export default function Text() {
  function ParallaxText({ children, baseVelocity = 500 }) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
      damping: 50,
      stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 1], {
      clamp: false,
    });

    const x = useTransform(baseX, (v) => (v));

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
      let moveBy = directionFactor.current * baseVelocity * (delta / 500);

      if (velocityFactor.get() < 0) {
        directionFactor.current = -1;
      } else if (velocityFactor.get() > 0) {
        directionFactor.current = 1;
      }

      moveBy += directionFactor.current * moveBy * velocityFactor.get();

      baseX.set(baseX.get() + moveBy);
    });

    return (
      <div className="w-screen h-full flex p-0 m-0 justify-center items-center bg-[#0e0d0d94] playfair-display ">
        <motion.div style={{ x }} className="p-0 m-0 flex flex-row flex-nowrap gap-2 justify-center" >
          <span className="overflow-hidden font-light text-pretty text-white text-[3rem] lg:text-[4rem] whitespace-nowrap">{children}</span>
        </motion.div>
      </div>
    );
  }

  return (
    <section className="w-screen min-h-[60vh] flex flex-col justify-center items-center overflow-hidden bg-cover bg-transport">
      <div className="relative z-10 text-center">
        <ParallaxText baseVelocity={-3}>Doświadczenie. Dokładność.</ParallaxText>
        <ParallaxText baseVelocity={5}>Zaufanie. Jakość. Terminowość.</ParallaxText>
      </div>
    </section>
  );
}
