import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa";

function Slider() {
  const navigate = useNavigate();

  return (
    <Carousel fade className="w-screen top-0 lg:max-h-[950px] overflow-hidden playfair-display">
      {[
        {
          imgSrc: "/ltl-transport-1.jpg",
          title: "Wieloletnie doświadczenie. Profesjonalna obsługa zleceń.",
          subtitle: "ul. Przemysłowa 20c, 44-290 Jejkowice",
          target: "kontakt",
        },
        {
          imgSrc: "/ltl-transport-1.jpg",
          title: "Globalny zakres usług. Profesjonalne wsparcie.",
          subtitle: "Indywidualne podejście do każdego klienta",
          target: "galeria",
        },
        {
          imgSrc: "/ltl-transport-1.jpg",
          title: "Atrakcyjne stawki i przejrzystość rozliczeń",
          subtitle: "Gwarancja jakości i terminowości przyjętych zleceń.",
          target: "oferta",
        },
      ].map((slide, index) => (
        <Carousel.Item key={index} className="lg:max-h-[950px] overflow-hidden">
          <div className="w-full h-[50vh] md:h-full relative">
            <div className="absolute inset-0 bg-black opacity-30"></div>
            <img
              src={slide.imgSrc}
              alt="Drewno kominkowe"
              className="w-full h-[50vh] md:h-full object-cover"
            />
          </div>
          <Carousel.Caption className="md:top-1/3">
            <div className="w-full lg:p-10 flex flex-col place-items-center text-white">
              <h3 className="text-[18px] sm:text-[48px] sm:py-3">{slide.title}</h3>
              <p className="lg:text-[24px] lg:py-2">{slide.subtitle}</p>
              <div
                className="cursor-pointer w-content bg-[#ec2a2a] my-1 md:my-2 lg:my-8 text-white text-[14px] lg:text-[24px] py-1 px-3 md:px-5 hover:rounded-3xl hover:-translate-y-1 transition-all flex-row gap-2 lg:gap-5 flex justify-center items-center no-underline"
                onClick={() => { navigate("/", { state: { targetId: slide.target } }); }}
              >
                {slide.target === "kontakt" ? "Skontaktuj się" : slide.target === "galeria" ? "Zobacz galerię" : "Sprawdź naszą ofertę"} <FaArrowRight size={24} />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Slider;

  