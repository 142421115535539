import React from 'react'
import Gallery from '../components/Gallery'
import Why from '../components/Why'
import Form from '../components/Form'
import Offert from '../components/Offert'
import Nav from '../components/Nav'
import Slider from '../components/Slider'
import Footer from '../components/Footer'
import Text from '../components/Text'
import transition
 from '../transition'
function Home() {

  return (
   <>
   <Nav  />
   <Slider/>
<Why />
<Text/>
<Offert/>
<Gallery/>
<Form/>
<Footer/>
</>
  )
}

export default transition(Home);