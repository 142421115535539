import React, { useEffect } from 'react';
import { ImTruck } from "react-icons/im";
import Nav from '../components/Nav';
import Slider from '../components/Slider';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import transition from '../transition';
import { FaArrowRight } from "react-icons/fa";
import FlyingLasers from '../components/FlyingLasers';

function Page() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);

  return (
    <>
      <Nav />
      <Slider />
      <div className="h-auto w-full flex flex-col text-white justify-center items-center relative playfair-display ">
        <FlyingLasers />
        <div id="onas" className="flex flex-row gap-2 justify-center items-center text-white pt-10 text-[36px] md:text-[48px]">
          <span className="p-3"><ImTruck style={{ color: '#e02525' }} size={56} /></span>O nas
        </div>
        <div className="text-white w-[90%] md:w-1/2 xl:w-1/3 py-20 px-1">
          <hr /> Szanowni Państwo.
          <p>
            Firma LTL EXPRESS działa na rynku przewozów zagranicznych oraz krajowych. Organizujemy transport i spedycję towarów na terenie Polski oraz w relacjach międzynarodowych. Transport międzynarodowy to nasza główna usługa. Mimo, iż jest to trudna dziedzina, nasi pracownicy potrafią zrealizować, każde zlecenie. Często pomagamy swoim klientom w sprawach formalnych, jak na przykład uzyskanie odpowiednich dokumentów z urzędów czy agencji celnych.
          </p>
          <p>
            Jesteśmy firmą, która stale się rozwija. Konkurencja na ryku spedycyjnym i transportowym to wyzwanie, które jest dla nas mobilizacją do świadczenia kompleksowych usług wysokiej jakości. Z nami klienci oszczędzają nie tylko czas ale i pieniądze, gdyż nasze usługi świadczymy w atrakcyjnych cenach. Zatrudniamy wykwalifikowanych pracowników, którzy posiadają odpowiednią wiedzę oraz doświadczenie, by skutecznie i profesjonalnie wykonywać zlecenia.
          </p>
        </div>
        <div className="z-50 cursor-pointer w-content bg-[#e02525] my-1 md:my-2 lg:my-8 text-white text-[14px] lg:text-[24px] py-1 px-3 md:px-5 hover:rounded-3xl hover:-translate-y-1 transition-all flex-row gap-2 lg:gap-5 flex justify-center items-center no-underline"
          onClick={() => { navigate("/", { state: { targetId: "kontakt" } }); }}>Skontaktuj się <FaArrowRight size={24} /></div>

        <div className="w-full flex justify-center items-center my-20">
          <div className="relative w-full flex justify-center items-center py-20">
            <div className="shadow-lg shadow-red-500 rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105 hover:shadow-xl">
              <iframe
                className="w-[600px] h-[300px] md:h-[400px] border-0"
                title="LTL Express Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.8874969597714!2d18.495129876893934!3d49.93845222409602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471156aa7e9cfc6b%3A0xea166250da58d4ee!2saleja%20Jana%20Paw%C5%82a%20II%2036%2C%2044-348%20Skrzysz%C3%B3w!5e0!3m2!1spl!2spl!4v1720008853707!5m2!1spl!2spl"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>

        {/* Karta z danymi kontaktowymi */}
        <div className="w-full flex flex-col items-center pb-10">
  <h3 className="text-xl text-white mb-4">Zespół LTL Express</h3>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-[90%] md:w-[60%] lg:w-[40%] z-40">
    {[
      { name: "Maciej Prochowski", role: "właściciel", email: "maciej@ltl-express.pl", phone: "665566557" },
      { name: "Kamil Kapłon", role: "spedytor", email: "kamil@ltl-express.pl", phone: "668225140" },
      { name: "Sergiusz Cichocki", role: "spedytor", email: "sergiusz@ltl-express.pl", phone: "735961538" },
      { name: "Patrycja Brzezina", role: "księgowość", email: "patrycja@ltl-express.pl", phone: "882675903" },
      { name: "Joanna Korbel", role: "księgowość", email: "joanna@ltl-express.pl", phone: "882675903" },
    ].map(({ name, role, email, phone }, index) => (
      <div key={index} className="p-4 bg-[#222] rounded-lg text-red-500 border border-red-500 transition-transform duration-300 hover:scale-105 flex flex-col">
        <div className="flex items-center">
          <h4 className="font-bold mr-2">{name}</h4>
          <span className="bg-red-600 text-white text-sm px-2 rounded-md">{role}</span>
        </div>
        <p className="mt-2">
          <strong>Email:</strong> 
          <a href={`mailto:${email}`} className="text-red-300 hover:underline ml-1">{email}</a>
        </p>
        <p>
          <strong>Tel:</strong> 
          <a href={`tel:${phone}`} className="text-red-300 hover:underline ml-1">{phone}</a>
        </p>
      </div>
    ))}
  </div>
</div>


      </div>
      <Footer />
    </>
  );
}

export default transition(Page);
